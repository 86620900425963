@charset "UTF-8";
@import '~swiper/swiper.min.css';
@import '~swiper/modules/navigation/navigation.min.css';
@import '~swiper/modules/pagination/pagination.min.css';
@font-face {
  font-family: "Noto Sans CJK KR";
  font-weight: 300;
  font-style: normal;
  src: url(../assets/fonts/NotoSansCJKkr-DemiLight-subset.woff) format("woff"), url(../assets/fonts/NotoSansCJKkr-DemiLight-subset.otf) format("opentype");
}
@font-face {
  font-family: "Noto Sans CJK KR";
  font-weight: 400;
  font-style: normal;
  src: url(../assets/fonts/NotoSansCJKkr-Regular-subset.woff) format("woff"), url(../assets/fonts/NotoSansCJKkr-Regular-subset.otf) format("opentype");
}
@font-face {
  font-family: "Noto Sans CJK KR";
  font-weight: 500;
  font-style: normal;
  src: url(../assets/fonts/NotoSansCJKkr-Medium-subset.woff) format("woff"), url(../assets/fonts/NotoSansCJKkr-Medium-subset.otf) format("opentype");
}
.progress-button {
  background-color: #f5f4f7;
  box-shadow: 0 11px 14px -7px rgba(33, 46, 58, 0.2);
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  margin: 0;
}
.progress-button span {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
  display: block;
}
.progress-button .progress-bar {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
  position: relative;
  top: -44px;
  height: 100%;
  width: 0;
  background: linear-gradient(to top, #2e8de1 0%, #b65eba 100%);
}
.progress-button .progress-bar.width1 {
  width: 25%;
}
.progress-button .progress-bar.width2 {
  width: 50%;
}
.progress-button .progress-bar.width3 {
  width: 75%;
}
.progress-button .progress-bar.width4 {
  width: 100%;
}

.call-button {
  background: linear-gradient(to right, #aa73f9, #eb7afa);
  width: 100%;
  height: 50px;
  margin: 0;
}
.call-button ion-row {
  height: 100%;
}
.call-button ion-row ion-grid {
  color: #ffffff;
  padding: 0;
  line-height: 50px;
  text-align: center;
}
.call-button img {
  width: 24px;
  height: 24px;
}
.call-button span {
  color: #ffffff;
  position: relative;
}
.call-button .red {
  color: red;
}

.add-clue-button {
  --background: transparent;
  background: #939ea8;
  border-radius: 5px;
  color: #ffffff;
}

.circle-button {
  width: 58px;
  height: 58px;
  background: linear-gradient(to right, #aa73f9, #eb7afa);
  border-radius: 29px;
}
.circle-button img {
  width: 36px;
  height: 36px;
}

.car-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 65px;
  height: 65px;
  color: #eb7afa;
  cursor: pointer;
}

.circle-text-button {
  width: 41px;
  height: 61px;
}
.circle-text-button .circle {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-bottom: 3px;
}
.circle-text-button .circle.qr {
  background-color: #aa00ff;
}
.circle-text-button .circle.remove {
  background-color: #ff5c5c;
}
.circle-text-button .circle img {
  width: 24px;
  height: 24px;
  display: unset;
}
.circle-text-button .text {
  font-family: "Noto Sans CJK KR";
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}
.circle-text-button .text.qr {
  color: #aa00ff;
}
.circle-text-button .text.remove {
  color: #ff5c5c;
}

.mypage-plan-button {
  display: inline-block;
  width: calc((100% - 30px) / 3);
  height: 80px;
  padding: 8px;
  border: 1px solid #939EA8;
  border-radius: 8px;
  position: relative;
  color: #939ea8;
}
.mypage-plan-button.day30 {
  background: linear-gradient(to right, #6198ff, #b4b4ff);
  border: 0;
  color: #ffffff;
}
.mypage-plan-button.day180 {
  background: linear-gradient(to right, #ff7a72, #ffceab);
  border: 0;
  color: #ffffff;
  margin: 0 10px;
}
.mypage-plan-button.day365 {
  background: linear-gradient(to right, #50baf9, #7df2ce);
  border: 0;
  color: #ffffff;
}
.mypage-plan-button .day span:nth-child(1) {
  font-family: "Noto Sans CJK KR";
  font-size: 23px;
  font-weight: 400;
}
.mypage-plan-button .day span:nth-child(2) {
  font-family: "Noto Sans CJK KR";
  font-size: 12px;
  font-weight: 400;
}
.mypage-plan-button .use {
  position: absolute;
  bottom: 8px;
  right: 8px;
}
.mypage-plan-button .use span {
  font-family: "Noto Sans CJK KR";
  font-size: 12px;
  font-weight: 400;
}

.plan-button {
  width: 94px;
  height: 100px;
  padding: 8px;
  border-radius: 8px;
  position: relative;
  border: 1px solid #e4e5ec;
  color: #e4e5ec;
  text-align: center;
}
.plan-button.day30 {
  background: linear-gradient(to right, #6198ff, #b4b4ff);
  color: #ffffff;
  border: 0;
  box-shadow: 0 6px 8px #6198ff;
}
.plan-button.day180 {
  background: linear-gradient(to right, #ff7a72, #ffceab);
  color: #ffffff;
  border: 0;
  box-shadow: 0 6px 8px #ff7a72;
}
.plan-button.day365 {
  background: linear-gradient(to right, #50baf9, #7df2ce);
  color: #ffffff;
  border: 0;
  box-shadow: 0 6px 8px #50baf9;
}
.plan-button .day span:nth-child(1) {
  font-family: "Noto Sans CJK KR";
  font-size: 23px;
  font-weight: 400;
}
.plan-button .day span:nth-child(2) {
  font-family: "Noto Sans CJK KR";
  font-size: 12px;
  font-weight: 400;
}
.plan-button .fee {
  position: absolute;
  bottom: 8px;
  right: 8px;
}
.plan-button .fee span {
  font-family: "Noto Sans CJK KR";
  font-size: 12px;
  font-weight: 400;
}

.menu-button {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  height: 50px;
  width: calc(50% - 7.5px);
  text-align: Center;
  line-height: 50px;
  color: #939ea8;
}
.menu-button.active {
  background: linear-gradient(to right, #aa73f9, #eb7afa);
  box-shadow: 0 11px 14px -7px rgba(33, 46, 58, 0.2);
  color: #ffffff;
  border-radius: 5px;
}
.menu-button.active.right {
  float: right;
}

.toast1 .toast-wrapper {
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 400;
  box-shadow: 0 2px 8px 0 rgba(147, 158, 168, 0.6);
  text-align: center;
  border-radius: 20px;
  background-color: #6d7c8a;
  height: 40px;
  z-index: 200;
  position: absolute;
  bottom: 100px;
}

.toast2 .toast-wrapper {
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 400;
  box-shadow: 0 2px 8px 0 rgba(147, 158, 168, 0.6);
  z-index: 10;
  text-align: center;
  border-radius: 20px;
  background: linear-gradient(to right, #aa73f9, #eb7afa);
  height: 40px;
  position: relative;
  bottom: 20px;
}

.toast-container {
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  height: 40px;
}

.alert-md .alert-wrapper {
  border-radius: 5px;
  width: 280px;
  height: 200px;
}
.alert-md .alert-wrapper .alert-head {
  color: #212e3a;
  width: 280px;
  height: 100px;
  text-align: center;
  padding: 40px 20px 0 20px;
}
.alert-md .alert-wrapper .alert-head .alert-title {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
}
.alert-md .alert-wrapper .alert-button-group {
  padding: 0;
  height: 50px;
}
.alert-md .alert-wrapper .alert-button-group .alert-button:nth-child(1) {
  border: 0;
  padding: 0;
  margin: 0;
  width: 140px;
}
.alert-md .alert-wrapper .alert-button-group .alert-button:nth-child(1) .button-inner {
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 400;
  color: #939ea8;
  justify-content: center;
}
.alert-md .alert-wrapper .alert-button-group .alert-button:nth-child(2) {
  border: 0;
  padding: 0;
  margin: 0;
  width: 140px;
}
.alert-md .alert-wrapper .alert-button-group .alert-button:nth-child(2) .button-inner {
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 400;
  color: #ff5c5c;
  justify-content: center;
}

.exit-alert .alert-wrapper {
  border-radius: 5px;
  width: 280px;
  height: 150px;
}
.exit-alert .alert-wrapper .alert-head {
  color: #212e3a;
  width: 280px;
  height: 100px;
  text-align: center;
  padding: 40px 20px 0 20px;
}
.exit-alert .alert-wrapper .alert-head .alert-title {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
}
.exit-alert .alert-wrapper .alert-button-group {
  padding: 0;
  height: 50px;
}
.exit-alert .alert-wrapper .alert-button-group .alert-button:nth-child(1) {
  border: 0;
  padding: 0;
  margin: 0;
  width: 140px;
}
.exit-alert .alert-wrapper .alert-button-group .alert-button:nth-child(1) .button-inner {
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 400;
  color: #939ea8;
  justify-content: center;
}
.exit-alert .alert-wrapper .alert-button-group .alert-button:nth-child(2) {
  border: 0;
  padding: 0;
  margin: 0;
  width: 140px;
}
.exit-alert .alert-wrapper .alert-button-group .alert-button:nth-child(2) .button-inner {
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 400;
  color: #ff5c5c;
  justify-content: center;
}

.default-input {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
  padding: 0;
}
.default-input .item-inner .input-wrapper .label {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
  color: #939ea8;
}
.default-input .item-inner .input-wrapper .input {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
  color: #212e3a;
}
.default-input .item-inner .input-wrapper .text-input {
  width: 70%;
}

.error-text {
  font-family: "Noto Sans CJK KR";
  font-size: 12px;
  font-weight: 400;
  color: #ff5c5c;
  padding-left: 16px;
}

.clue-input-title, .inquiry-input-title {
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 400;
  color: #212e3a;
  margin: 0 !important;
}
.clue-input-title .text-input, .inquiry-input-title .text-input {
  margin: 0 !important;
}
.clue-input-title input, .inquiry-input-title input {
  margin: 0 !important;
}
.clue-input-title ::ng-deep .item-ios.item-label-stacked .text-input, .clue-input-title .item-ios.item-label-floating .text-input, .inquiry-input-title ::ng-deep .item-ios.item-label-stacked .text-input, .inquiry-input-title .item-ios.item-label-floating .text-input {
  margin: 0 !important;
}
.clue-input-title.h20, .inquiry-input-title.h20 {
  height: 20px;
  line-height: 20px;
}

.clue-input-subtitle, .inquiry-input-answer, .clue-input-text {
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 400;
  padding: 0 !important;
}

.explain {
  font-family: "Noto Sans CJK KR";
  font-size: 12px;
  font-weight: 400;
  color: #939ea8;
}

.text-input-md, .text-input-ios {
  margin: 25px 0 12px 0;
}

.item-input ion-label {
  width: 150px;
}

.item-ios.item-block .item-inner {
  border: 0;
}

.item-ios {
  padding: 0;
}

ion-textarea textarea {
  margin: 0 !important;
  min-height: 100px;
}

a {
  color: #939ea8;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

.blink {
  -webkit-animation: blink 2.5s linear infinite;
}

@-webkit-keyframes blink {
  0% {
    background-color: red;
  }
  33% {
    background-color: yellow;
  }
  66% {
    background-color: blue;
  }
  100% {
    background-color: green;
  }
  /*    from { background-color: red;}
      to {background-color: green;}  */
}
.dropdown-container {
  display: inline-block;
}
.dropdown-container .dropdown {
  color: #939ea8;
  width: 100px;
  height: 57px;
  padding: 18px 0 0 0;
  line-height: 40px;
  border-bottom: 1px solid #e4e5ec;
}
.dropdown-container .dropdown.select {
  color: #212e3a;
  border-bottom: 1px solid #aa00ff;
}
.dropdown-container .dropdown span {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
  padding: 0;
}
.dropdown-container .dropdown img {
  width: 24px;
  height: 24px;
  position: relative;
  top: 7px;
  left: 0;
  float: right;
}
.dropdown-container .dropdown-option {
  width: 100px;
  height: 144px;
  background-color: #e4e5ec;
  position: absolute;
  z-index: 10;
}
.dropdown-container .dropdown-option .option {
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 400;
  color: #212e3a;
  height: 48px;
  padding: 12px 10px;
}

.searchbar-ios {
  padding: 35px 30px;
  min-height: 44px;
  border-top: 0.55px solid transparent;
  border-bottom: 0;
  background: #ffffff;
}

.searchbar-md .searchbar-input {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.searchbar-md {
  background: inherit;
  padding: 25px;
}

ion-header .toolbar {
  padding: 0 30px;
}
ion-header .toolbar .toolbar-background {
  background: transparent;
}
ion-header .toolbar ion-buttons .left {
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 400;
  color: #939ea8;
}
ion-header .toolbar ion-buttons .right {
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 400;
  color: #aa00ff;
}

.upload-menu {
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding-left: 10px;
  color: #939ea8;
  height: 80px;
  width: 100%;
  margin-bottom: 15px;
}
.upload-menu .img {
  height: 100%;
  position: relative;
  top: 5px;
}
.upload-menu .delete {
  width: 50%;
  float: left;
  height: 40px;
}
.upload-menu .change {
  width: 50%;
  float: right;
  height: 40px;
}
.upload-menu .take {
  width: 50%;
  float: right;
  height: 40px;
}
.upload-menu .get {
  width: 50%;
  float: left;
  height: 40px;
}

.empty-space40 {
  width: 100%;
  height: 40px;
}

.title {
  font-family: "Noto Sans CJK KR";
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  color: #6d7c8a !important;
}

.label-md[stacked] {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
}

.label-ios[stacked] {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
}

.inicis {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 30000;
}

.swiper {
  width: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/**
 * Explain : 공통으로 사용될 레이아웃인경우 이곳에 정의
 */
.top-margin-container {
  margin-top: 5.6rem;
}

ion-page, .nav-decor, content-md, content-ios, ion-app.ios, .fixed-content, .scroll-content {
  background-color: transparent !important;
}

@media (min-width: 961px) {
  app-root .ion-page {
    padding: 0 33% !important;
    background: #e4e5ec;
  }
}

/**
 * Explain : 페이지 상단 title
 */
.icon-list {
  border: 0 !important;
  height: 54px;
  padding: 0;
}
.icon-list .item-inner {
  padding: 0;
}
.icon-list .item-inner .input-wrapper .label {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
  height: 54px;
  color: #ff5c5c;
  line-height: 54px;
  margin: 0;
}
.icon-list .item-inner .input-wrapper .label ion-img {
  width: 24px;
  height: 24px;
  position: relative;
  top: 6px;
  background: transparent;
  color: #ff5c5c;
}
.icon-list .item-inner .input-wrapper .icon-input {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
  position: relative;
  border: 0;
  width: 150px;
  height: 54px;
  line-height: 54px;
  float: right;
}
.icon-list .item-inner .input-wrapper .icon-input .text-input {
  margin: 0;
}

.text-list {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  height: 54px;
}
.text-list .item-inner {
  padding: 0 !important;
}
.text-list .item-inner .first {
  color: #939ea8;
}
.text-list .item-inner .first.email {
  max-width: 20%;
}
.text-list .item-inner .second {
  color: #212e3a;
}
.text-list .item-inner .label {
  margin: 0 !important;
}
.text-list.delete {
  color: #ff5c5c !important;
}
.text-list.no-border {
  border-bottom: 0 !important;
}

.board-list {
  border-bottom: 1px solid #e4e5ec;
  border-top: 0 !important;
}
.board-list h2 {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
}
.board-list p {
  font-family: "Noto Sans CJK KR";
  font-size: 12px;
  font-weight: 400;
  color: #939ea8;
}
.board-list .date, .board-list .name {
  font-family: "Noto Sans CJK KR";
  font-size: 12px;
  font-weight: 400;
  color: #939ea8;
}

.accordion-list {
  margin-bottom: 0;
  border-bottom: 1px solid #f5f4f7;
}
.accordion-list ion-img {
  width: 24px;
  height: 24px;
  float: right;
  background: transparent;
}
.accordion-list .notice-list {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
  color: #212e3a;
  height: 72px;
  border-top: 0 !important;
}

.select-list {
  height: 58px;
  width: 100px;
}
.select-list .item-inner {
  padding: 0 !important;
}
.select-list .item-inner ion-img {
  float: right;
  position: relative;
  top: 2px;
}

ion-list ion-item .item-inner {
  padding: 0 !important;
}

.list-md .item-input:last-child {
  border-bottom: 0;
}

.item-md.item-input.ng-invalid.ng-touched:not(.input-has-focus):not(.item-input-has-focus) .item-inner {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.item-md.item-input.item-input-has-focus.item-inner,
.item-md.item-input.input-has-focus .item-inner {
  border-bottom-color: #aa00ff;
}

.item-md.item-input.item-input-has-focus .item-inner,
.item-md.item-input.input-has-focus .item-inner {
  box-shadow: none;
  -webkit-box-shadow: none !important;
}

.list-ios .item-block .item-inner {
  border-bottom: 0;
}

.list-ios > .item-block:last-child, .list-ios > .item-wrapper:last-child .item-block {
  border-bottom: 0;
}

.list-ios {
  margin: 0;
}

.list-md {
  margin: 0;
}

.list-md .item-input.ng-valid.item-input-has-value:not(.input-has-focus):not(.item-input-has-focus):last-child, .list-md .item-input.ng-valid.input-has-value:not(.input-has-focus):not(.item-input-has-focus):last-child {
  -webkit-box-shadow: none;
  box-shadow: none;
}

ion-app.md {
  background-color: transparent;
}

.item-md.item-block .item-inner {
  border-bottom: 0;
}

ion-input.ng-dirty {
  border-bottom: 1px solid #aa00ff;
}
ion-input.ng-invalid {
  border-bottom: 1px solid #ff5c5c;
}
.item-ios.item-label-floating [item-right], .item-ios.item-label-stacked [item-end], .item-ios.item-label-floating [item-end] {
  margin-top: 13px;
  margin-bottom: 6px;
}

.item-md {
  padding: 0;
}

.add-card {
  margin: 0;
  border-radius: 5px;
  width: 100%;
}
.add-card .icon-list {
  height: 54px;
}
.add-card .icon-list .label {
  margin: 0;
  line-height: 32px;
  position: relative;
}
.add-card .icon-list ion-label {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
  color: #6d7c8a;
  line-height: 32px;
  padding: 0 16px;
}
.add-card .icon-list ion-label ion-img {
  width: 24px;
  height: 24px;
  position: relative;
  top: 7px;
  background: transparent;
}
.add-card .icon-list .icon-input {
  position: relative;
  top: 3px;
  border: 0;
  width: 150px;
  float: right;
}

.switch-card .title {
  margin-left: 15px;
}

.slide-card {
  height: 94px;
  width: calc(100% + 60px);
  padding: 16px 30px;
  position: relative;
  left: -30px;
  top: -104px;
  margin: 0;
}
.slide-card ion-card-content {
  font-family: "Noto Sans CJK KR";
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  width: 55%;
  padding: 5px 0;
  height: 60px;
  line-height: 150%;
}
.slide-card .circle-text-button {
  display: inline-block;
  float: right;
}
.slide-card .circle-text-button:nth-child(2) {
  margin-left: 30px;
}