.dropdown-container{
  display:inline-block;
  .dropdown{
    color:$color-grey-4;
    width:100px;
    height:57px;
    padding:18px 0 0 0;
    line-height:40px;
    border-bottom: 1px solid $color-grey-3;
    &.select{
      color: $color-grey-6;
      border-bottom: 1px solid $color-purple-1;
    }
    span{
      @include font(body2);
      padding:0;
    }
    img{
      width:24px;
      height:24px;
      position:relative;
      top:7px;
      left:0;
      float:right;
    }
  }

  .dropdown-option{
    width:100px;
    height:144px;
    background-color:$color-grey-3;
    position: absolute;
    z-index:10;
    .option{
      @include font(body1);
      color:$color-grey-6;
      height:48px;
      padding:12px 10px;
    }
  }

}
