.icon-list{
  border: 0 !important;
  height: 54px;
  padding: 0;
  .item-inner{
    padding:0;
    .input-wrapper{
      .label{
        @include font(body2);
        height:54px;
        color: $color-red-1;
        line-height:54px;
        margin:0;
        ion-img{
          width:24px;
          height:24px;
          position: relative;
          top: 6px;
          background:transparent;
          color:$color-red-1;
        }
      }
      .icon-input{
        @include font(body2);
        position: relative;
        border: 0;
        width: 150px;
        height:54px;
        line-height:54px;
        float: right;
        .text-input{
          margin:0;
        }
      }
    }
  }
}

.text-list{
  @include font(body2);
  //border-bottom: 1px solid $color-grey-4 !important;
  padding:0;
  height: 54px;
  .item-inner{
    padding:0 !important;
    .first{
      color:$color-grey-4;
      &.email{
        max-width:20%;
      }
    }
    .second{
      color:$color-grey-6;
    }
    .label{
      margin:0 !important;
    }
  }
  &.delete{
    color:$color-red-1 !important;
  }
  &.no-border{
    border-bottom: 0 !important;
  }
}

.board-list{
  border-bottom: 1px solid $color-grey-3;
  border-top: 0 !important;
  h2{
    @include font(body2);
  }
  p{
    @include font(caption);
    color:$color-grey-4;
  }
  .date, .name{
    @include font(caption);
    color:$color-grey-4;
  }
}

.accordion-list{
  margin-bottom:0;
  border-bottom: 1px solid $color-grey-2;
  ion-img{
    width:24px;
    height:24px;
    float:right;
    background:transparent;
  }
  .notice-list{
    @include font(body2);
    color:$color-grey-6;
    height: 72px;
    border-top:0 !important;
  }
}

.select-list {
  height: 58px;
  width: 100px;
  .item-inner{
    padding:0 !important;
    ion-img{
      float:right;
      position:relative;
      top:2px;
    }
  }
}


ion-list{
  ion-item{
    .item-inner{
      padding: 0 !important;
    }
  }
}

.list-md .item-input:last-child {
  border-bottom: 0;
}
.item-md.item-input.ng-invalid.ng-touched:not(.input-has-focus):not(.item-input-has-focus) .item-inner {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.item-md.item-input.item-input-has-focus.item-inner,
.item-md.item-input.input-has-focus .item-inner{
  border-bottom-color: $color-purple-1;
}

.item-md.item-input.item-input-has-focus .item-inner,
.item-md.item-input.input-has-focus .item-inner{
  box-shadow:none;
  -webkit-box-shadow: none !important;
}

.list-ios .item-block .item-inner {
  border-bottom: 0;
}

.list-ios > .item-block:last-child, .list-ios > .item-wrapper:last-child .item-block{
  border-bottom: 0;
}

.list-ios {
  margin: 0;
}

.list-md {
  margin: 0;
}

.list-md .item-input.ng-valid.item-input-has-value:not(.input-has-focus):not(.item-input-has-focus):last-child, .list-md .item-input.ng-valid.input-has-value:not(.input-has-focus):not(.item-input-has-focus):last-child {
  -webkit-box-shadow: none;
  box-shadow: none;
}

ion-app.md {
  //font-family: "Roboto", "Helvetica Neue", sans-serif;
  //font-size: 1.4rem;
  background-color: transparent;
}

.item-md.item-block .item-inner {
  border-bottom: 0;
}

ion-input{
  &.ng-dirty{
    border-bottom: 1px solid $color-purple-1;
  }
  &.ng-invalid{
    border-bottom: 1px solid $color-red-1;
  }
  &.ng-pristine{
    //border-bottom: 1px solid $color-grey-3;
  }
}

.item-ios.item-label-floating [item-right], .item-ios.item-label-stacked [item-end], .item-ios.item-label-floating [item-end] {
  margin-top: 13px;
  margin-bottom: 6px;
}

.item-md{
  padding:0;
}
