//.searchbar{
//  height:100px !important;
//  background:transparent !important;
//  border-bottom:0;
//  .searchbar-input-container{
//    .searchbar-search-icon{
//      color: $color-purple-1;
//      top: 5px;
//    }
//    .searchbar-input{
//      @include font(subheading);
//      color: $color-grey-6;
//      padding-left: 40px;
//      box-shadow:none;
//    }
//  }
//}
//
//.searchbar .searchbar-input-container .searchbar-input{
//  margin-left: 10px;
//}

.searchbar-ios {
  padding: 35px 30px;
  min-height: 44px;
  border-top: 0.55px solid transparent;
  border-bottom: 0;
  background: $color-grey-1;
}

.searchbar-md .searchbar-input {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.searchbar-md {
  background: inherit;
  padding: 25px;
}
