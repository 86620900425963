.toast1{
  .toast-wrapper{
    @include font(body1);
    box-shadow: 0 2px 8px 0 rgba(147, 158, 168, 0.6);
    text-align:center;
    border-radius:20px;
    background-color: $color-grey-5;
    height:40px;
    z-index:200;
    position:absolute;
    bottom:100px;
  }
}

.toast2{
  .toast-wrapper{
    @include font(body1);
    box-shadow: 0 2px 8px 0 rgba(147, 158, 168, 0.6);
    z-index: 10;
    text-align:center;
    border-radius:20px;
    background: $color-gradient-4;
    height:40px;
    position:relative;
    bottom:20px;
  }
}

.toast-container{
  @include font(body1);
  text-align:center;
  height:40px;
}
//
//.joinToast{
//  .toast-wrapper{
//    background:$color-gradient-4;
//  }
//}


