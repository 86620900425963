.progress-button{
  background-color: $color-grey-2;
  box-shadow: 0 11px 14px -7px rgba(33, 46, 58, 0.2);
  width:100%;
  height:44px;
  line-height:44px;
  text-align:center;
  margin: 0;
  span{
    @include font(body2);
    display: block;
  }
  .progress-bar{
    @include font(body2);
    position:relative;
    top: -44px;
    height:100%;
    width: 0;
    background: $color-gradient-6;
    &.width1{
      width:25%;
    }
    &.width2{
      width:50%;
    }
    &.width3{
      width:75%;
    }
    &.width4{
      width:100%;
    }
  }
}

.call-button{
  background: $color-gradient-4;
  width:100%;
  //box-shadow: 0 11px 14px -7px rgba(33, 46, 58, 0.2);
  height:50px;
  margin:0;
  ion-row{
    height: 100%;
    ion-grid {
      color: #ffffff;
      padding: 0;
      line-height: 50px;
      text-align: center;
    }
  }
  img{
    width:24px;
    height:24px;
  }
  span{
    color: #ffffff;
    position: relative;
  }
  .red{
    color:red;
  }
}

.add-clue-button{
  --background: transparent;
  background: $color-grey-4;
  border-radius: 5px;
  color:$color-grey-1;
}

.circle-button{
  width:58px;
  height:58px;
  background: $color-gradient-4;
  border-radius:29px;
  img{
    width:36px;
    height:36px;
  }
}

.car-button{
  position: absolute;
  top: 20px;
  right: 20px;
  width: 65px;
  height: 65px;
  color: #eb7afa;
  cursor: pointer;
}

.circle-text-button{
  width:41px;
  height:61px;
  .circle{
    width:40px;
    height:40px;
    border-radius:20px;
    margin-bottom:3px;
    &.qr{
      background-color: #aa00ff;
    }
    &.remove{
      background-color: #ff5c5c;
    }
    img{
      width:24px;
      height:24px;
      display:unset;
    }
  }
  .text{
    @include font(caption);
    text-align:center;
    &.qr{
      color: #aa00ff;
    }
    &.remove{
      color: #ff5c5c;
    }
  }
}

.mypage-plan-button{
  display:inline-block;
  width: calc((100% - 30px)/3);
  height: 80px;
  padding:8px;
  border:1px solid #939EA8;
  border-radius:8px;
  position:relative;
  color:$color-grey-4;
  &.day30{
    background: $color-gradient-3;
    border:0;
    color: $color-grey-1;
  }
  &.day180{
    background: $color-gradient-1;
    border:0;
    color: $color-grey-1;
    margin: 0 10px;
  }
  &.day365{
    background: $color-gradient-2;
    border:0;
    color: $color-grey-1;
  }
  .day{
    span:nth-child(1){
      @include font(title1);
    }
    span:nth-child(2){
      @include font(caption);
    }
  }
  .use{
    position: absolute;
    bottom: 8px;
    right: 8px;
    span{
      @include font(caption);
    }
  }
}

.plan-button{
  width:94px;
  height:100px;
  padding:8px;
  border-radius:8px;
  position:relative;
  border: 1px solid $color-grey-3;
  color: $color-grey-3;
  text-align: center;
  &.day30{
    background: linear-gradient(to right, #6198ff, #b4b4ff);
    color:$color-grey-1;
    border:0;
    box-shadow:0 6px 8px #6198ff;
  }
  &.day180{
    background: $color-gradient-1;
    color:$color-grey-1;
    border:0;
    box-shadow:0 6px 8px #ff7a72;
  }
  &.day365{
    background: $color-gradient-2;
    color:$color-grey-1;
    border:0;
    box-shadow:0 6px 8px #50baf9;
  }
  .day{
    span:nth-child(1){
      @include font(title1);
    }
    span:nth-child(2){
      @include font(caption);
    }
  }
  .fee{
    position: absolute;
    bottom: 8px;
    right: 8px;
    span{
      @include font(caption);
    }
  }
}

.menu-button{
  @include font(body2);
  display:inline-block;
  height:50px;
  width: calc((100% / 2) - 7.5px);
  text-align:Center;
  line-height:50px;
  color: $color-grey-4;
  &.active{
    background: $color-gradient-4;
    box-shadow: 0 11px 14px -7px rgba(33, 46, 58, 0.2);
    color:$color-grey-1;
    border-radius: 5px;
    &.right{
      float:right;
    }
  }
}
