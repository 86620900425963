.title {
  @include font(body1);
  margin: 0;
  color: $color-grey-5 !important;
}

.label-md[stacked] {
  @include font(body2);
}

.label-ios[stacked] {
  @include font(body2);
}
