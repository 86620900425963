@import "variable";

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-weight: 300;
  font-style: normal;
  src: url(../assets/fonts/NotoSansCJKkr-DemiLight-subset.woff) format('woff'),
  url(../assets/fonts/NotoSansCJKkr-DemiLight-subset.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-weight: 400;
  font-style: normal;
  src: url(../assets/fonts/NotoSansCJKkr-Regular-subset.woff) format('woff'),
  url(../assets/fonts/NotoSansCJKkr-Regular-subset.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans CJK KR';
  font-weight: 500;
  font-style: normal;
  src: url(../assets/fonts/NotoSansCJKkr-Medium-subset.woff) format('woff'),
  url(../assets/fonts/NotoSansCJKkr-Medium-subset.otf) format('opentype');
}

$typographyBreakPoint: (
  caption: (
    size: 12px,
    height: 18px,
    weight: 400,
  ),
  body1: (
    size: 14px,
    height: 20px,
    weight: 400,
  ),
  body2: (
    size: 16px,
    height: 24px,
    weight: 400,
  ),
  subheading: (
    size: 18px,
    height: 27px,
    weight: 400,
  ),
  title1: (
    size: 23px,
    height: 34px,
    weight: 400,
  ),
  title2: (
    size: 30px,
    height: 45px,
    weight: 400,
  )
);

@mixin font($type) {
  font-family: 'Noto Sans CJK KR';
  font-size: map-get(map-get($typographyBreakPoint, $type), size);
  font-weight: map-get(map-get($typographyBreakPoint, $type), weight);
}
