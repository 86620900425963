$color-grey-1: #ffffff;
$color-grey-2: #f5f4f7;
$color-grey-3: #e4e5ec;
$color-grey-4: #939ea8;
$color-grey-5: #6d7c8a;
$color-grey-6: #212e3a;

$color-purple-1: #aa00ff;

$color-red-1: #ff5c5c;

$color-gradient-1: linear-gradient(to right, #ff7a72, #ffceab);
$color-gradient-2: linear-gradient(to right, #50baf9,  #7df2ce);
$color-gradient-3: linear-gradient(to right, #6198ff, #b4b4ff);
$color-gradient-4: linear-gradient(to right, #aa73f9, #eb7afa);
$color-gradient-5: linear-gradient(to top, #261a61, #342483);
$color-gradient-6: linear-gradient(to top, #2e8de1 0%, #b65eba 100%)
