ion-header{
  .toolbar{
    padding: 0 30px;
    .toolbar-background{
      background: transparent;
    }
    ion-buttons{
      .left{
        @include font(body1);
        color:$color-grey-4;
      }
      .right{
        @include font(body1);
        color:$color-purple-1;
      }
    }
  }
}
