.default-input{
  @include font(body2);
  padding:0;
  .item-inner{
    .input-wrapper{
      .label{
        @include font(body2);
        color: $color-grey-4;
      }
      .input{
        @include font(body2);
        color: $color-grey-6;
      }
      .text-input{
        width:70%;
      }
    }
  }
}

.error-text {
  @include font(caption);
  color: $color-red-1;
  padding-left:16px;
}

.clue-input-title, .inquiry-input-title{
  @include font(body1);
  color:$color-grey-6;
  margin:0 !important;
  .text-input{
    margin:0 !important;
  }
  input{
    margin:0 !important;
  }
  ::ng-deep .item-ios.item-label-stacked .text-input, .item-ios.item-label-floating .text-input{
    margin: 0 !important;
  }
  .text-input-md:focus{}
  &.h20{
    height:20px;
    line-height:20px;
  }
}

.clue-input-subtitle, .inquiry-input-answer, .clue-input-text{
  @include font(body1);
  padding: 0 !important;
}

//.error-text{
//  @include font(caption);
//  padding:0;
//  margin:0;
//}

.explain{
  @include font(caption);
  color:$color-grey-4;
}

.text-input-md, .text-input-ios{
  margin: 25px 0 12px 0;
}

.item-input ion-label {
  width:150px;
}

.item-ios.item-block .item-inner {
  border: 0;
}

.item-ios {
  padding:0;
}

ion-textarea{
  textarea{
    margin:0 !important;
    min-height: 100px;
  }
}

a{
  color:$color-grey-4;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

.blink {
  -webkit-animation: blink 2.5s linear infinite;
}
@-webkit-keyframes blink {
  0% { background-color: red; }
  33% { background-color: yellow; }
  66% { background-color: blue; }
  100% { background-color: green; }
  /*    from { background-color: red;}
      to {background-color: green;}  */
}
