@mixin mobile {
  @media (max-width: 780px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 781px) and (max-width: 960px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 961px) {
    @content;
  }
}

@mixin boxshadow ($hor, $ver, $all, $rgba) {
  -moz-box-shadow: $hor $ver $all $rgba;
  -webkit-box-shadow: $hor $ver $all $rgba;
  box-shadow: $hor $ver $all $rgba;
  //box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
}

// .box { @include border-radius(10px); }
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

@mixin linear-gradient($from, $to) {
  background: linear-gradient(to right, $from, $to);
}

@mixin vertical-gradient($from, $to) {
  background: linear-gradient($from, $to);
}
