.upload-menu{
  @include font(body1);
  text-align:left;
  padding-left: 10px;
  color: $color-grey-4;
  height:80px;
  width:100%;
  margin-bottom:15px;
  .img{
    height:100%;
    position: relative;
    top: 5px;
  }
  .delete{
    width:50%;
    float:left;
    height:40px;
  }
  .change{
    width:50%;
    float:right;
    height:40px;
  }
  .take{
    width:50%;
    float:right;
    height:40px;
  }
  .get{
    width:50%;
    float:left;
    height:40px;
  }
}

.empty-space40{
  width: 100%;
  height: 40px;
}
