/**
 * Explain : 공통으로 사용될 레이아웃인경우 이곳에 정의
 */
.top-margin-container {
  margin-top: 5.6rem;
}


ion-page, .nav-decor, content-md, content-ios,ion-app.ios,.fixed-content,.scroll-content{

  background-color: transparent !important;
}

app-root{
  .ion-page{
    @include desktop() {
      padding: 0 33% !important;
      background: $color-grey-3;
    }
  }
}

