.add-card{
  margin:0;
  border-radius: 5px;
  width:100%;
  .icon-list{
    height: 54px;
    .label{
      margin:0;
      line-height:32px;
      position: relative;
    }
    ion-label{
      @include font(body2);
      color: $color-grey-5;
      line-height:32px;
      padding: 0 16px;
      ion-img{
        width:24px;
        height:24px;
        position: relative;
        top: 7px;
        background:transparent;
      }
    }
    .icon-input{
      position: relative;
      top: 3px;
      border: 0;
      width: 150px;
      float: right;
    }
  }
}

.switch-card {

  .title{
    margin-left: 15px;
  }
}

.slide-card{
  height:94px;
  width: calc(100% + 60px);
  padding:16px 30px;
  position:relative;
  left:-30px;
  top:-104px;
  margin:0;
  ion-card-content{
    @include font(body2);
    display:inline-block;
    width:55%;
    padding:5px 0;
    height:60px;
    line-height:150%;
  }

  .circle-text-button{
    display: inline-block;
    float: right;
  }
  .circle-text-button:nth-child(2){
    margin-left:30px;
  }
}
