.alert-wrapper{
  //border-radius:5px;
  //box-shadow:0 0 14px $color-grey-5;
  //width:280px;
  //height:170px;
  //.alert-head{
  //  color:$color-grey-6;
  //  width: 280px;
  //  height: 100px;
  //  text-align: center;
  //  padding: 40px 20px 0 20px;
  //  .alert-title{
  //    @include font(body2);
  //  }
  //}
  //.alert-button-group{
  //  padding:0;
  //  height:50px;
  //  .alert-button:nth-child(1){
  //    border:0;
  //    padding: 0;
  //    margin: 0;
  //    .button-inner{
  //      @include font(body1);
  //      color:$color-grey-4;
  //      justify-content:center;
  //    }
  //  }
  //  .alert-button:nth-child(2){
  //    border:0;
  //    padding: 0;
  //    margin: 0;
  //    .button-inner{
  //      @include font(body1);
  //      color:$color-red-1;
  //      justify-content:center;
  //    }
  //  }
  //}
}

.alert-md{
  .alert-wrapper {
    border-radius: 5px;
    //box-shadow:0 0 14px $color-grey-5;
    width: 280px;
    height: 200px;
    .alert-head {
      color: $color-grey-6;
      width: 280px;
      height: 100px;
      text-align: center;
      padding: 40px 20px 0 20px;
      .alert-title {
        @include font(body2);
      }
    }
    .alert-button-group {
      padding: 0;
      height: 50px;
      .alert-button:nth-child(1) {
        border: 0;
        padding: 0;
        margin: 0;
        width:140px;
        .button-inner {
          @include font(body1);
          color: $color-grey-4;
          justify-content: center;
        }
      }
      .alert-button:nth-child(2) {
        border: 0;
        padding: 0;
        margin: 0;
        width:140px;
        .button-inner {
          @include font(body1);
          color: $color-red-1;
          justify-content: center;
        }
      }
    }
  }
}

.exit-alert{
  .alert-wrapper {
    border-radius: 5px;
    //box-shadow:0 0 14px $color-grey-5;
    width: 280px;
    height: 150px;
    .alert-head {
      color: $color-grey-6;
      width: 280px;
      height: 100px;
      text-align: center;
      padding: 40px 20px 0 20px;
      .alert-title {
        @include font(body2);
      }
    }
    .alert-button-group {
      padding: 0;
      height: 50px;
      .alert-button:nth-child(1) {
        border: 0;
        padding: 0;
        margin: 0;
        width:140px;
        .button-inner {
          @include font(body1);
          color: $color-grey-4;
          justify-content: center;
        }
      }
      .alert-button:nth-child(2) {
        border: 0;
        padding: 0;
        margin: 0;
        width:140px;
        .button-inner {
          @include font(body1);
          color: $color-red-1;
          justify-content: center;
        }
      }
    }
  }
}
